import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo2.png'

const Header = () => {
  const navTogglerRef = useRef(null);
  const navbarRef = useRef(null);
  const navbarLinksRef = useRef([]);

  // useEffect(() => {
  //   const navToggler = navTogglerRef.current;
  //   const navbar = navbarRef.current;
  //   const navbarLinks = navbarLinksRef.current;

  //   if (!navToggler || !navbar || !navbarLinks.length) return;

  //   const addEventOnElem = (elem, type, callback) => {
  //     if (elem.length > 1) {
  //       for (let i = 0; i < elem.length; i++) {
  //         elem[i].addEventListener(type, callback);
  //       }
  //     } else {
  //       elem.addEventListener(type, callback);
  //     }
  //   };

  //   const toggleNavbar = () => {
  //     navbar?.classList.toggle("active");
  //     navToggler?.classList.toggle("active");
  //   };

  //   const closeNavbar = () => {
  //     navbar?.classList.remove("active");
  //     navToggler?.classList.remove("active");
  //   };

  //   const activeElemOnScroll = () => {
  //     const header = document.querySelector("[data-header]");
  //     const backTopBtn = document.querySelector("[data-back-top-btn]");

  //     if (window.scrollY > 100 &&  window.location.pathname === '/') {
  //       header?.classList.add("active");
  //       backTopBtn?.classList.add("active");
  //     } else if (window.location.pathname !== '/') {
  //       header?.classList.add("active");
  //       backTopBtn?.classList.add("active");
  //     } else {
  //       header?.classList.remove("active");
  //       backTopBtn?.classList.remove("active");
  //     }
  //   };

  //   addEventOnElem(navToggler, "click", toggleNavbar);
  //   addEventOnElem(navbarLinks, "click", closeNavbar);
  //   window.addEventListener("scroll", activeElemOnScroll);

  //   return () => {
  //     window.removeEventListener("scroll", activeElemOnScroll);
  //   };
  // }, []);

  return (
    <header className="header" data-header>
      <div className="container">

        <button className="nav-toggle-btn" aria-label="toggle manu" data-nav-toggler ref={navTogglerRef}>
          <ion-icon name="menu-outline" aria-hidden="true" className="menu-icon"></ion-icon>
          <ion-icon name="close-outline" aria-label="true" className="close-icon"></ion-icon>
        </button>

        {/* <a href="#" className="logo">Kitter</a> */}
        <img src={logo} width="80" height="80" loading="lazy" alt="Cat Toys" className="logo" />

        <nav className="navbar" data-navbar ref={navbarRef}>
          <ul className="navbar-list">

            <li className="navbar-item">
              <Link to="/" className="navbar-link" data-nav-link ref={(el) => navbarLinksRef.current[0] = el}>Home</Link>
            </li>

            <li className="navbar-item">
              <Link to="/shop" className="navbar-link" data-nav-link ref={(el) => navbarLinksRef.current[1] = el}>Shop</Link>
            </li>

            <li className="navbar-item">
              <Link to="/diet" className="navbar-link" data-nav-link ref={(el) => navbarLinksRef.current[2] = el}>Diet</Link>
            </li>

            {/* <li className="navbar-item">
              <Link to="/about" className="navbar-link" data-nav-link ref={(el) => navbarLinksRef.current[3] = el}>About</Link>
            </li> */}

            <li className="navbar-item">
              <Link to="/contact" className="navbar-link" data-nav-link ref={(el) => navbarLinksRef.current[4] = el}>Contact Us</Link>
            </li>

          </ul>

        </nav>
      </div>
    </header>
  );
}

export default Header;
