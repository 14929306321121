import React from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import product1 from '../../assets/images/starter.jpg'
import product2 from '../../assets/images/puppy.jpg'
import product3 from '../../assets/images/adult.jpg'
import product4 from '../../assets/images/puppy-5kg.jpg'
import product5 from '../../assets/images/adult-20kg.jpg'
import product6 from '../../assets/images/puppy-10kg.jpg'
import product7 from '../../assets/images/adult-10kg.jpg'
import product8 from '../../assets/images/puppy-20kg.jpg'
import product9 from '../../assets/images/adult-20kg.jpg'

import product10 from '../../assets/images/starter-back.jpg'
import product20 from '../../assets/images/puppy-back.jpg'
import product30 from '../../assets/images/adult-back.jpg'
import product40 from '../../assets/images/puppy-5kg-back.jpg'
import product50 from '../../assets/images/adult-5kg-back.jpg'
import product60 from '../../assets/images/puppy-10kg-back.jpg'
import product70 from '../../assets/images/adult-10kg-back.jpg'
import product80 from '../../assets/images/puppy-20kg-back.jpg'
import product90 from '../../assets/images/adult-20kg-back.jpg'

const Shop = () => {
    return (
        <React.Fragment>
            <Header />
            <section className="section product mt-5" id="shop" aria-label="product">
                <div className="container">

                    <h2 className="h2 section-title">
                        <span className="span">Best</span> Seller Products
                    </h2>

                    <ul className="grid-list">

                        <li>
                            <div className="product-card">

                                <div className="card-banner img-holder" style={{ '--width': 360, '--height': 360 }}>
                                    <img src={product1} width="360" height="360" loading="lazy"
                                        alt="Commodo leo sed porta" className="img-cover default" />
                                    <img src={product10} width="360" height="360" loading="lazy"
                                        alt="Commodo leo sed porta" className="img-cover hover" />

                                    {/* <button className="card-action-btn" aria-label="add to card" title="Add To Card">
                                        <ion-icon name="bag-add-outline" aria-hidden="true"></ion-icon>
                                    </button> */}
                                </div>

                                <div className="card-content">

                                    {/* <div className="wrapper">
                                        <div className="rating-wrapper">
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                        </div>

                                        <span className="span">(1)</span>
                                    </div> */}

                                    <h3 className="h3">
                                        <a href="#" className="card-title">1KG Starter</a>
                                    </h3>

                                    <data className="card-price" value="15">₹649</data>

                                </div>

                            </div>
                        </li>

                        <li>
                            <div className="product-card">

                                <div className="card-banner img-holder" style={{ '--width': 360, '--height': 360 }}>
                                    <img src={product2} width="360" height="360" loading="lazy"
                                        alt="Purus consequat congue sit" className="img-cover default" />
                                    <img src={product20} width="360" height="360" loading="lazy"
                                        alt="Purus consequat congue sit" className="img-cover hover" />

                                    {/* <button className="card-action-btn" aria-label="add to card" title="Add To Card">
                                        <ion-icon name="bag-add-outline" aria-hidden="true"></ion-icon>
                                    </button> */}
                                </div>

                                <div className="card-content">

                                    {/* <div className="wrapper">
                                        <div className="rating-wrapper gray">
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                        </div>

                                        <span className="span">(0)</span>
                                    </div> */}

                                    <h3 className="h3">
                                        <a href="#" className="card-title">1KG Puppy</a>
                                    </h3>

                                    <data className="card-price" value="45">₹449</data>

                                </div>

                            </div>
                        </li>

                        <li>
                            <div className="product-card">

                                <div className="card-banner img-holder" style={{ '--width': 360, '--height': 360 }}>
                                    <img src={product3} width="360" height="360" loading="lazy"
                                        alt="Morbi vel arcu scelerisque" className="img-cover default" />
                                    <img src={product30} width="360" height="360" loading="lazy"
                                        alt="Morbi vel arcu scelerisque" className="img-cover hover" />

                                    {/* <button className="card-action-btn" aria-label="add to card" title="Add To Card">
                                        <ion-icon name="bag-add-outline" aria-hidden="true"></ion-icon>
                                    </button> */}
                                </div>

                                <div className="card-content">

                                    {/* <div className="wrapper">
                                        <div className="rating-wrapper gray">
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                        </div>

                                        <span className="span">(0)</span>
                                    </div> */}

                                    <h3 className="h3">
                                        <a href="#" className="card-title">1KG Adult</a>
                                    </h3>

                                    <data className="card-price" value="45">₹399</data>

                                </div>

                            </div>
                        </li>

                        <li>
                            <div className="product-card">

                                <div className="card-banner img-holder" style={{ '--width': 360, '--height': 360 }}>
                                    <img src={product4} width="360" height="360" loading="lazy"
                                        alt="Morbi vel arcu scelerisque" className="img-cover default" />
                                    <img src={product40} width="360" height="360" loading="lazy"
                                        alt="Morbi vel arcu scelerisque" className="img-cover hover" />

                                    {/* <button className="card-action-btn" aria-label="add to card" title="Add To Card">
                                        <ion-icon name="bag-add-outline" aria-hidden="true"></ion-icon>
                                    </button> */}
                                </div>

                                <div className="card-content">

                                    {/* <div className="wrapper">
                                        <div className="rating-wrapper gray">
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                        </div>

                                        <span className="span">(0)</span>
                                    </div> */}

                                    <h3 className="h3">
                                        <a href="#" className="card-title">5KG Puppy</a>
                                    </h3>

                                    <data className="card-price" value="49">₹1450</data>

                                </div>

                            </div>
                        </li>

                        <li>
                            <div className="product-card">

                                <div className="card-banner img-holder" style={{ '--width': 360, '--height': 360 }}>
                                    <img src={product5} width="360" height="360" loading="lazy"
                                        alt="Morbi vel arcu scelerisque" className="img-cover default" />
                                    <img src={product50} width="360" height="360" loading="lazy"
                                        alt="Morbi vel arcu scelerisque" className="img-cover hover" />

                                    {/* <button className="card-action-btn" aria-label="add to card" title="Add To Card">
                                        <ion-icon name="bag-add-outline" aria-hidden="true"></ion-icon>
                                    </button> */}
                                </div>

                                <div className="card-content">

                                    {/* <div className="wrapper">
                                        <div className="rating-wrapper gray">
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                        </div>

                                        <span className="span">(0)</span>
                                    </div> */}

                                    <h3 className="h3">
                                        <a href="#" className="card-title">5KG Adult</a>
                                    </h3>

                                    <data className="card-price" value="85">₹1350</data>

                                </div>

                            </div>
                        </li>

                        <li>
                            <div className="product-card">

                                <div className="card-banner img-holder" style={{ '--width': 360, '--height': 360 }}>
                                    <img src={product6} width="360" height="360" loading="lazy"
                                        alt="Nam justo libero porta ege" className="img-cover default" />
                                    <img src={product60} width="360" height="360" loading="lazy"
                                        alt="Nam justo libero porta ege" className="img-cover hover" />

                                    {/* <button className="card-action-btn" aria-label="add to card" title="Add To Card">
                                        <ion-icon name="bag-add-outline" aria-hidden="true"></ion-icon>
                                    </button> */}
                                </div>

                                <div className="card-content">

                                    {/* <div className="wrapper">
                                        <div className="rating-wrapper gray">
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                        </div>

                                        <span className="span">(0)</span>
                                    </div> */}

                                    <h3 className="h3">
                                        <a href="#" className="card-title">10KG Puppy</a>
                                    </h3>

                                    <data className="card-price" value="85">₹2490</data>

                                </div>

                            </div>
                        </li>

                        <li>
                            <div className="product-card">

                                <div className="card-banner img-holder" style={{ '--width': 360, '--height': 360 }}>
                                    <img src={product7} width="360" height="360" loading="lazy"
                                        alt="Nam justo libero porta ege" className="img-cover default" />
                                    <img src={product70} width="360" height="360" loading="lazy"
                                        alt="Nam justo libero porta ege" className="img-cover hover" />

                                    {/* <button className="card-action-btn" aria-label="add to card" title="Add To Card">
                                        <ion-icon name="bag-add-outline" aria-hidden="true"></ion-icon>
                                    </button> */}
                                </div>

                                <div className="card-content">

                                    {/* <div className="wrapper">
                                        <div className="rating-wrapper gray">
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                        </div>

                                        <span className="span">(0)</span>
                                    </div> */}

                                    <h3 className="h3">
                                        <a href="#" className="card-title">10KG Adult</a>
                                    </h3>

                                    <data className="card-price" value="85">₹2390</data>

                                </div>

                            </div>
                        </li>

                        <li>
                            <div className="product-card">

                                <div className="card-banner img-holder" style={{ '--width': 360, '--height': 360 }}>
                                    <img src={product8} width="360" height="360" loading="lazy"
                                        alt="Etiam commodo leo sed" className="img-cover default" />
                                    <img src={product80} width="360" height="360" loading="lazy"
                                        alt="Etiam commodo leo sed" className="img-cover hover" />

                                    {/* <button className="card-action-btn" aria-label="add to card" title="Add To Card">
                                        <ion-icon name="bag-add-outline" aria-hidden="true"></ion-icon>
                                    </button> */}
                                </div>

                                <div className="card-content">

                                    {/* <div className="wrapper">
                                        <div className="rating-wrapper gray">
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                        </div>

                                        <span className="span">(0)</span>
                                    </div> */}

                                    <h3 className="h3">
                                        <a href="#" className="card-title">20KG Puppy</a>
                                    </h3>

                                    <data className="card-price" value="55">₹4450</data>

                                </div>

                            </div>
                        </li>

                        <li>
                            <div className="product-card">

                                <div className="card-banner img-holder" style={{ '--width': 360, '--height': 360 }}>
                                    <img src={product9} width="360" height="360" loading="lazy"
                                        alt="Etiam commodo leo sed" className="img-cover default" />
                                    <img src={product90} width="360" height="360" loading="lazy"
                                        alt="Etiam commodo leo sed" className="img-cover hover" />

                                    {/* <button className="card-action-btn" aria-label="add to card" title="Add To Card">
                                        <ion-icon name="bag-add-outline" aria-hidden="true"></ion-icon>
                                    </button> */}
                                </div>

                                <div className="card-content">

                                    {/* <div className="wrapper">
                                        <div className="rating-wrapper gray">
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                            <ion-icon name="star" aria-hidden="true"></ion-icon>
                                        </div>

                                        <span className="span">(0)</span>
                                    </div> */}

                                    <h3 className="h3">
                                        <a href="#" className="card-title">20KG Adult</a>
                                    </h3>

                                    <data className="card-price" value="55">₹4350</data>

                                </div>

                            </div>
                        </li>

                    </ul>

                </div>
            </section>
            <Footer />
        </React.Fragment>
    )
}

export default Shop