import React from 'react';
import Header from '../common/Header';
import banner from '../../assets/images/Diet.jpg'
import diet1 from '../../assets/images/Starter-Diet.jpg';
import diet2 from '../../assets/images/Puppy-Diet.jpg';
import diet3 from '../../assets/images/Adult-Diet.jpg';


const Diet = () => {
  return (
    <React.Fragment>
      <Header />
      <main style={{marginTop: '8rem'}}>
        <article>
        <section className="section hero has-bg-image mt-5" id="home" aria-label="home"
            style={{backgroundImage: `url(${banner})`}}>
            {/* <div className="container">

            <h1 className="h1 hero-title">
                <span className="span">Best Nutrition</span> Food
            </h1>

            <p className="hero-text">Sale up to 40% off today</p>

            <Link to="/shop" className="btn" style={{marginTop: '2rem'}}>Shop Now</Link>

            </div> */}
        </section>

        {/* <section className="section category" aria-label="category">
            <div className="container">

            <h2 className="h2 section-title">
                <span className="span">Diet</span> categories
            </h2>

            <ul className="has-scrollbar category-card-center">

              <li className="scrollbar-item">
                <div className="category-card">

                    <figure className="card-banner img-holder" style={{'--width': 50, '--height': 50}}>
                        <img src={diet1} width="330" height="300" loading="lazy" alt="Cat Food"
                        className="img-cover" />
                    </figure>

                    <h3 className="h3">
                    <a href="#" className="card-title">Starter</a>
                    </h3>

                </div>
                </li>

                <li className="scrollbar-item">
                <div className="category-card">

                    <figure className="card-banner img-holder" style={{'--width': 50, '--height': 50}}>
                    <img src={diet2} width="330" height="300" loading="lazy" alt="Cat Toys"
                        className="img-cover" />
                    </figure>

                    <h3 className="h3">
                    <a href="#" className="card-title">Puppy</a>
                    </h3>

                </div>
                </li>

                <li className="scrollbar-item">
                <div className="category-card">

                    <figure className="card-banner img-holder" style={{'--width': 50, '--height': 50}}>
                    <img src={diet3} width="330" height="300" loading="lazy" alt="Dog Food"
                        className="img-cover" />
                    </figure>

                    <h3 className="h3">
                    <a href="#" className="card-title">Adult</a>
                    </h3>

                </div>
              </li>

            </ul>

            </div>
        </section> */}

        {/* <!-- Carousel --> */}
        <div id="demo" className="carousel slide" data-bs-ride="carousel" data-bs-interval="800">

        {/* <!-- Indicators/dots --> */}
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#demo" data-bs-slide-to="0" className="active"></button>
          <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
          <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
        </div>

        {/* <!-- The slideshow/carousel --> */}
        <div className="carousel-inner" style={{width: "90%"}}>
          <div className="carousel-item active">
            <img src={diet1} alt="Los Angeles" className="d-block" style={{width: '100%'}} />
            <div className="carousel-caption text-dark">
              <h3>Los Angeles</h3>
              <p>We had such a great time in LA!</p>
            </div>
          </div>
          <div className="carousel-item">
            <img src={diet2} alt="Chicago" className="d-block" style={{width: '100%'}} />
            <div className="carousel-caption text-dark">
              <h3>Los Angeles</h3>
              <p>We had such a great time in LA!</p>
            </div>
          </div>
          <div className="carousel-item">
            <img src={diet3} alt="New York" className="d-block" style={{width: '100%'}} />
            <div className="carousel-caption text-dark">
              <h3>Los Angeles</h3>
              <p>We had such a great time in LA!</p>
            </div>
          </div>
        </div>

        {/* <!-- Left and right controls/icons --> */}
        <button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" style={{backgroundColor: 'darkgray'}}></span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
          <span className="carousel-control-next-icon" style={{backgroundColor: 'darkgray'}}></span>
        </button>
        </div>


        </article>
      </main>
    </React.Fragment>
  )
}

export default Diet