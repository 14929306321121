import React from 'react';
import footer from  '../../assets/images/footer-bg.jpg';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">

    <div className="footer-top section">
      <div className="container">

        <div className="footer-brand">

          <a href="#" className="logo">Pupzo Pro</a>

          <p className="footer-text">
            If you have any question, please contact us at <a href="mailto:pupzopro@gmail.com"
              className="link">pupzopro@gmail.com</a>
          </p>

          {/* <ul className="contact-list">

            <li className="contact-item">
              <ion-icon name="location-outline" aria-hidden="true"></ion-icon>

              <address className="address">
                B-1034 Sagartal Road, Near By Pahua Wali Mata, Gwalior, Madhya Pradesh 474012
              </address>
            </li>

            <li className="contact-item">
              <ion-icon name="call-outline" aria-hidden="true"></ion-icon>

              <a href="tel:+918817604003" className="contact-link">(+91) 881-760-4003</a>
            </li>

          </ul>

          <ul className="social-list">

            <li>
              <a href="https://wa.me/message/KHETCZU2JYFGB1" className="social-link" target="_blank">
                <ion-icon name="logo-whatsapp"></ion-icon>
              </a>
            </li>

            <li>
              <a href="https://www.instagram.com/pupzopro?igsh=MXh4MGJ6cm8xZjd1dw==" className="social-link" target="_blank">
                <ion-icon name="logo-instagram"></ion-icon>
              </a>
            </li>

          </ul> */}

        </div>

        <div className="footer-brand">

          <ul className="contact-list">

            <li>
              <p className="contact-item-title">Get In Touch</p>
            </li>

            <li className="contact-item">
              <ion-icon name="location-outline" aria-hidden="true"></ion-icon>

              <address className="address">
                B-1034 Sagartal Road, Near By Pahua Wali Mata, Gwalior, Madhya Pradesh 474012
              </address>
            </li>

            <li className="contact-item">
              <ion-icon name="mail-outline" aria-hidden="true"></ion-icon>

              <a href="mailto:+918817604003" className="footer-link">pupzopro@gmail.com</a>
            </li>

            <li className="contact-item">
              <ion-icon name="call-outline" aria-hidden="true"></ion-icon>

              <a href="tel:+918817604003" className="footer-link">(+91) 881-760-4003</a>
            </li>

          </ul>

          {/* <ul className="social-list">

            <li>
              <a href="https://wa.me/message/KHETCZU2JYFGB1" className="social-link" target="_blank">
                <ion-icon name="logo-whatsapp"></ion-icon>
              </a>
            </li>

            <li>
              <a href="https://www.instagram.com/pupzopro?igsh=MXh4MGJ6cm8xZjd1dw==" className="social-link" target="_blank">
                <ion-icon name="logo-instagram"></ion-icon>
              </a>
            </li>

          </ul> */}

        </div>

        <ul className="footer-list">

          <li>
            <p className="footer-list-title">Popular Link</p>
          </li>

          <li>
            <Link to="/" className="footer-link">Home</Link>
          </li>

          <li>
            <Link to="/shop" className="footer-link">Shop</Link>
          </li>

          <li>
            <Link to="/diet" className="footer-link">Diet</Link>
          </li>
{/* 
          <li>
            <Link to="/about" className="footer-link">About Us</Link>
          </li> */}

          <li>
            <Link to="/contact" className="footer-link">Contact Us</Link>
          </li>

        </ul>

        <ul className="social-list">
          <li>
            <p className="footer-list-title">Follow Us</p>
          </li> <br />

          <li>
              <a href="https://wa.me/message/KHETCZU2JYFGB1" className="social-link" target="_blank">
                <ion-icon name="logo-whatsapp"></ion-icon>
              </a>
            </li>

            <li>
              <a href="https://www.instagram.com/pupzopro?igsh=MXh4MGJ6cm8xZjd1dw==" className="social-link" target="_blank">
                <ion-icon name="logo-instagram"></ion-icon>
              </a>
            </li>
        </ul>


        {/* <div className="footer-brand">

          <ul className="social-list">

            <li>
              <p className="footer-list-title">Follow Us</p>
            </li>

            <li>
              <a href="https://wa.me/message/KHETCZU2JYFGB1" className="social-link" target="_blank">
                <ion-icon name="logo-whatsapp"></ion-icon>
              </a>
            </li>

            <li>
              <a href="https://www.instagram.com/pupzopro?igsh=MXh4MGJ6cm8xZjd1dw==" className="social-link" target="_blank">
                <ion-icon name="logo-instagram"></ion-icon>
              </a>
            </li>

          </ul>

        </div> */}

      </div>
    </div>

    <div className="footer-bottom">
      <div className="container" style={{ display: 'flex', justifyContent: 'center'}}>

        <p className="copyright">
          &copy; {new Date().getFullYear()} made with ♥ by <Link to="/" className="copyright-link">Pupzo Pro</Link>
        </p>

      </div>
    </div>

  </footer>
  )
}

export default Footer