import React from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import banner from '../../assets/images/home.png'

import category1 from '../../assets/images/starter.jpg'
import category2 from '../../assets/images/puppy.jpg'
import category3 from '../../assets/images/adult.jpg'
// import offerBanner1 from '../../assets/images/offer-banner-1.jpg'
import offerBanner1 from '../../assets/images/1.png'
import offerBanner2 from '../../assets/images/2.png'
import offerBanner3 from '../../assets/images/3.png'
import serviceImage from '../../assets/images/service-image.png'
import serviceIcon1 from '../../assets/images/service-icon-1.png'
import serviceIcon2 from '../../assets/images/service-icon-2.png'
import serviceIcon3 from '../../assets/images/service-icon-3.png'
import serviceIcon4 from '../../assets/images/service-icon-4.png'
import ctaBg from '../../assets/images/cta-bg.jpg'
import ctaBanner from '../../assets/images/cta-banner.png'
import ctaIcon from '../../assets/images/cta-icon.png'
import { Link } from 'react-router-dom';


const Home = () => {
  return (
    <React.Fragment>
        <Header />
        <main>
        <article>

        <section className="section hero has-bg-image" id="home" aria-label="home"
            style={{
                backgroundImage: `url(${banner})`,
                backgroundSize: 'cover', 
                backgroundPosition: 'center'
                }}>
            <div className="container">

            <h1 className="h1 hero-title" style={{marginLeft: '-70px'}}>
                <span className="span">Best Nutrition</span> Food
            </h1>

            {/* <p className="hero-text">Sale up to 40% off today</p> */}

            <Link to="/shop" className="btn" style={{marginTop: '2rem'}}>Shop Now</Link>

            </div>
        </section>



        <section className="section category" aria-label="category">
            <div className="container">

            <h2 className="h2 section-title">
                <span className="span">Top</span> categories
            </h2>

            <ul className="has-scrollbar category-card-center">

                <li className="scrollbar-item">
                <div className="category-card">

                    <figure className="card-banner img-holder-category" style={{'--width': 100, '--height': 100,}}>
                        <img src={category1} width="180" height="200" loading="lazy" alt="Dog Food Starter"
                        style={{content: 'fit'}}
                        className="img-cover" />
                    </figure>

                    <h3 className="h3">
                    <a href="#" className="card-title">Starter</a>
                    </h3>

                </div>
                </li>

                <li className="scrollbar-item">
                <div className="category-card">

                    <figure className="card-banner img-holder-category" style={{'--width': 100, '--height': 100}}>
                    <img src={category2} width="180" height="200" loading="lazy" alt="Dog Food Puppy"
                        className="img-cover" />
                    </figure>

                    <h3 className="h3">
                    <a href="#" className="card-title">Puppy</a>
                    </h3>

                </div>
                </li>

                <li className="scrollbar-item">
                <div className="category-card">

                    <figure className="card-banner img-holder-category" style={{'--width': 100, '--height': 108}}>
                    <img src={category3} width="180" height="200" loading="lazy" alt="Dog Food Adult"
                        className="img-cover" />
                    </figure>

                    <h3 className="h3">
                    <a href="#" className="card-title">Adult</a>
                    </h3>

                </div>
                </li>

                {/* <li className="scrollbar-item">
                <div className="category-card">

                    <figure className="card-banner img-holder" style={{'--width': 150, '--height': 150}}>
                    <img src={category4} width="330" height="300" loading="lazy" alt="Dog Toys"
                        className="img-cover" />
                    </figure>

                    <h3 className="h3">
                    <a href="#" className="card-title">Dog Toys</a>
                    </h3>

                </div>
                </li>

                <li className="scrollbar-item">
                <div className="category-card">

                    <figure className="card-banner img-holder" style={{'--width': 150, '--height': 150}}>
                    <img src={category5} width="330" height="300" loading="lazy"
                        alt="Dog Sumpplements" className="img-cover" />
                    </figure>

                    <h3 className="h3">
                    <a href="#" className="card-title">Dog Sumpplements</a>
                    </h3>

                </div>
                </li> */}

            </ul>

            </div>
        </section>



        <section className="section offer" aria-label="offer">
            <div className="container">

            <h2 className="h2 section-title">
                <span className="span">Diet</span> Nutrition
            </h2>


            <ul className="grid-list">

                <li>
                <div className="offer-card has-bg-image img-holder"
                    style={{backgroundImage: `url(${offerBanner1})`, '--width': 540, '--height': 374}}>
                    <h3 className="h3 card-title">
                        Wholesome &<span className="span">Balanced Diet</span>
                    </h3>
                    <p className="card-subtitle" style={{marginBottom: '5rem', width: '60%'}}>Omeaga-3 fatty acids from fish oil for a healthy heart</p>
                    <Link to="/diet" className="btn">Read More</Link>
                </div>
                </li>

                <li>
                <div className="offer-card has-bg-image img-holder"
                    style={{backgroundImage: `url(${offerBanner2})`, '--width': 540, '--height': 374}}>
                    <h3 className="h3 card-title">
                        Tasty & <span className="span">Digests Easily</span>
                    </h3>
                    <p className="card-subtitle" style={{marginBottom: '5rem', width: '70%'}}>Highly digestible ingredients for improved digestion</p>
                    <Link to="/diet" className="btn">Read More</Link>
                </div>
                </li>

                <li>
                <div className="offer-card has-bg-image img-holder"
                    style={{backgroundImage: `url(${offerBanner3})`, '--width': 540, '--height': 374}}>
                    <h3 className="h3 card-title">
                        Immune Defense,<span className="span">No More Sick Days</span>
                    </h3>
                    <p className="card-subtitle" style={{marginBottom: '5rem', width: '60%'}}>Vitamin E and sekenium for strong system</p>
                    <Link to="/diet" className="btn">Read More</Link>
                </div>
                </li>

            </ul>

            </div>
        </section>


        <section className="section service" aria-label="service">
            <div className="container">

            <img src={serviceImage} width="122" height="136" loading="lazy" alt="" className="img" />

            <h2 className="h2 section-title">
                <span className="span">Your pet's essentials, </span> 
                precisely provided for their joy and health.
            </h2>

            <ul className="grid-list service-card-center">

                <li>
                <div className="service-card">

                    <figure className="card-icon">
                    <img src={serviceIcon1} width="70" height="70" loading="lazy"
                        alt="service icon" />
                    </figure>

                    <h3 className="h3 card-title">Online Delivery</h3>

                    <p className="card-text">
                    Get what you need online, delivered right to your door!
                    </p>

                </div>
                </li>
{/* 
                <li>
                <div className="service-card">

                    <figure className="card-icon">
                    <img src={serviceIcon2} width="70" height="70" loading="lazy"
                        alt="service icon" />
                    </figure>

                    <h3 className="h3 card-title">30 Day Return</h3>

                    <p className="card-text">
                    35% off your first order plus 5% off all future orders.
                    </p>

                </div>
                </li>

                <li>
                <div className="service-card">

                    <figure className="card-icon">
                    <img src={serviceIcon3} width="70" height="70" loading="lazy"
                        alt="service icon" />
                    </figure>

                    <h3 className="h3 card-title">Security payment</h3>

                    <p className="card-text">
                    25% off your online order of $50+. Available at most locations.
                    </p>

                </div>
                </li> */}

                <li>
                    <div className="service-card">

                        <figure className="card-icon">
                        <img src={serviceIcon4} width="70" height="70" loading="lazy"
                            alt="service icon" />
                        </figure>

                        <h3 className="h3 card-title">Support</h3>

                        <p className="card-text">
                        Our team is available from 10 AM to 6 PM.
                        </p>

                    </div>
                </li>

            </ul>

            </div>
        </section>



        {/* <section className="cta has-bg-image" aria-label="cta" style={{backgroundImage: `url(${ctaBg})`}}>
            <div className="container">

            <figure className="cta-banner">
                <img src={ctaBanner} width="900" height="660" loading="lazy" alt="cat" className="w-100" />
            </figure>

            <div className="cta-content">

                <img src={ctaIcon} width="120" height="35" loading="lazy" alt="taste guarantee"
                className="img" />

                <h2 className="h2 section-title">Taste it, love it or we’ll replace it… Guaranteed!</h2>

                <p className="section-text">
                At Petio, we believe your dog and cat will love their food so much that if they don’t … we’ll help you
                find a
                replacement. That’s our taste guarantee.
                </p>

                <a href="#" className="btn">Find out more</a>

            </div>

            </div>
        </section> */}

        </article>
    </main>
        <Footer />
    </React.Fragment>
  )
}

export default Home