import React, { useState } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
// import "./Bootstrap.min.css";
import "./Contact.css";


const Contact = () => {
    const [formData, setFormData] = useState({});

    const onChangeHandler = (e) => {
        const {name, value} = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    return (
        <React.Fragment>
            <Header />
            <div className="container-fluid py-5 mt-5" style={{marginTop: '300px'}}>
                <div className="container">
                    <div className="border-start border-5 ps-5 mb-5" style={{maxWidth: "600px"}}>
                        <h6 className="text-primary text-uppercase">Contact Us</h6>
                        <h1 className="display-5 text-uppercase mb-0">Please Feel Free To Contact Us</h1>
                    </div>
                    <div className="row g-5">
                        <div className="col-lg-7">
                            <form>
                                <div className="row g-3">
                                    <div className="col-12">
                                        <input type="text" name="name" className="form-control bg-light border-0 px-4" placeholder="Your Name" style={{height: "55px"}} onChange={onChangeHandler} />
                                    </div>
                                    <div className="col-12">
                                        <input type="email" name="email" className="form-control bg-light border-0 px-4" placeholder="Your Email" style={{height: "55px"}} onChange={onChangeHandler} />
                                    </div>
                                    <div className="col-12">
                                        <input type="text" name="subject" className="form-control bg-light border-0 px-4" placeholder="Subject" style={{height: "55px"}} onChange={onChangeHandler} />
                                    </div>
                                    <div className="col-12">
                                        <textarea className="form-control bg-light border-0 px-4 py-3" name="message" rows="8" placeholder="Message" onChange={onChangeHandler}></textarea>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-5">
                            <div className="bg-light mb-5 p-5">
                                <div className="d-flex align-items-center mb-2">
                                    <ion-icon className="fs-1 text-primary me-3" name="location-outline" style={{paddingTop: '2rem', paddingRight: '1rem'}} aria-hidden="true"></ion-icon>
                                    <div className="text-start">
                                        <h6 className="text-uppercase mb-1">Our Office</h6>
                                        <span>B-1034 Sagartal Road, Gwalior</span>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                    <ion-icon className="fs-1 text-primary me-3" name="mail-outline" style={{paddingTop: '2rem', paddingRight: '1rem'}} aria-hidden="true"></ion-icon>
                                    <div className="text-start">
                                        <h6 className="text-uppercase mb-1">Email Us</h6>
                                        <span>pupzopro@gmail.com</span>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <ion-icon className="fs-1 text-primary me-3" name="call-outline" style={{paddingTop: '2rem', paddingRight: '1rem'}} aria-hidden="true"></ion-icon>
                                    <div className="text-start">
                                        <h6 className="text-uppercase mb-1">Call Us</h6>
                                        <span>+91 881-760-4003</span>
                                    </div>
                                </div>
                                <div>
                                    <iframe className="position-relative w-100"
                                        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Anand%20Nagar+(PupzoPro)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                                        frameBorder="0" style={{height: "205px", border:0}} allowFullScreen="" aria-hidden="false"
                                        tabIndex="0"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default Contact