import { Routes, Route } from "react-router-dom";
// import './App.css';

import Home from "./components/home/Home";
import Shop from "./components/shop/Shop";
import Diet from "./components/diet/Diet";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/shop" element={<Shop />} />
      <Route path="/diet" element={<Diet />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
}

export default App;
